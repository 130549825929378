<template>
  <div class="flex-1 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="mx-auto max-w-max">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold text-primary-500 sm:text-5xl">
          404
        </p>
        <div class="sm:ml-6">
          <div class="sm:u-border-gray-200 sm:border-l sm:pl-6">
            <h1 class="text-4xl font-extrabold tracking-tight u-text-gray-900 sm:text-5xl">
              Not Found
            </h1>
            <p class="mt-1 text-xl">
              This is not the page you're looking for.
            </p>
          </div>
          <div class="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <PathwayButton href="/" type="primary-lb">
              Go back home
            </PathwayButton>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
/* Refresh once on cache error */
if (process.client && window.sessionStorage) {
  if (!sessionStorage.getItem('chunk404')) {
    sessionStorage['chunk404'] = true
    window.location.reload()
  } else {
    sessionStorage.removeItem('chunk404')
  }
}
</script>
